import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-black shadow-md py-4 mt-5">
      <div className="container mx-auto text-center py-3">
        <p className="text-sm sm:text-base text-gray-500">
          ShowMy.Pics is Powered By{' '}
          <a href="https://peenak.com" className="text-blue-500 hover:text-blue-700 underline transition-colors duration-300">
            Peenak
          </a>
        </p>

        <div className="mt-2">
          <Link to="/" className="text-gray-500 hover:text-gray-700 mx-2">
            Home
          </Link>
          <Link to="/legal" className="text-gray-500 hover:text-gray-700 mx-2">
            Legal
          </Link>
          <Link to="/credits" className="text-gray-500 hover:text-gray-700 mx-2">
            Credits
          </Link>
          <Link to="/help" className="text-gray-500 hover:text-gray-700 mx-2">
            Help
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
